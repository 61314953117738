<template>
    <div class="flex align-center badge-box" :class="status">
        <div class="badge">
            <div class="after" />
        </div>
        <div class="m-l-4" :class="fontColor?'font-color':''">
            {{title}}
        </div>
    </div>
</template>

<script>
export default {
    name: "Badge",
    props: {
        status: {
            type: String,
            default: "default",
        },
        title: {
            type: String,
            default: "default",
        },
        fontColor: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.badge {
    position: relative;
    top: 0px;
    display: inline-block;
    width: 6px;
    height: 6px;
    vertical-align: middle;
    border-radius: 50%;
}
.badge-box:hover {
    .after {
        box-sizing: border-box;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 1px solid;
        border-radius: 50%;
        animation: ring 1.2s ease-in-out infinite;
        content: "";
    }
}
@keyframes ring {
    0% {
        transform: scale(0.8);
        opacity: 0.9;
    }
    100% {
        transform: scale(2.4);
        opacity: 0;
    }
}
.default {
    .badge {
        background-color: #409eff;
        .after {
            border-color: #409eff !important;
        }
    }
    .font-color {
        color: #409eff;
    }
}
.danger {
    .badge {
        background-color: #f56c6c;
        .after {
            border-color: #f56c6c !important;
        }
    }
    .font-color {
        color: #f56c6c;
    }
}
.success {
    .badge {
        background-color: #67c23a;
        .after {
            border-color: #67c23a !important;
        }
    }
    .font-color {
        color: #67c23a;
    }
}
.warning {
    .badge {
        background-color: #e6a23c;
        .after {
            border-color: #e6a23c !important;
        }
    }
    .font-color {
        color: #e6a23c;
    }
}
.info {
    .badge {
        background-color: #909399;
        .after {
            border-color: #909399 !important;
        }
    }
    .font-color {
        color: #909399;
    }
}
</style>