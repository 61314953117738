<template>
    <div class="footer-view flex flex-j-sa" v-if="this.$route.path !== '/doExam' && this.$route.path !== '/student/studyCourse'">
        <div class="flex-c align-center">
            <div class="hyperlink-list fs14">
                <div v-for="(item, index) in hyperLinkList" :key="index">
                    <a :href="item.url" target="“_blank”">{{ item.title }}</a>
                </div>
            </div>
            <div class="flex fs22 m-t-20">
                <div class="m-r-20">子与龙齐</div>
                <div>青云平步</div>
            </div>
            <div class="flex fs14 m-b-20">
                <div class="m-r-8">ZIYULONGQI</div>
                <div>QINGYUNPINGBU</div>
            </div>
            <div class="hyperlink-list flex align-center fs14">
                <div>Copyright 2023-现在 齐龙科技有限公司</div>
                <div><a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备2021012541号-2</a></div>
                <div>
                    <a target="_blank" class="flex align-center" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51019002005316">
                        <img src="../assets/nationalEmblem.png" class="m-r-8"/>
                        <p style="color:#b3b3b3;">川公网安备 51019002005316号</p>
                    </a>
                </div>
            </div>
            <div class="hyperlink-list fs14 m-t-8">
                地址：成都市高新区花样年香年广场T2写字楼3011
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            hyperLinkList: [
                {
                    title: "关于我们",
                    url: "https://www.qilongkj.com/cover-o4nm3pei/aboutUs",
                },
                {
                    title: "联系我们",
                    url: "https://www.qilongkj.com/cover-o4nm3pei/aboutUs",
                },
                {
                    title: "用户协议",
                    url: "./用户协议.html",
                },
                {
                    title: "隐私政策",
                    url: "./隐私协议.html",
                },
                {
                    title: "帮助与反馈",
                    url: "./帮助与反馈.html",
                },
                {
                    title: "侵权投诉",
                    url: "./侵权投诉.html",
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
.footer-view {
    color: #b3b3b3;
    background-color: $fontColor;
    padding: 40px 0;
    .hyperlink-list {
        div {
            display: inline-block;
            padding: 0 20px;
            > a {
                text-decoration: none;
                color: inherit;
            }
        }
        div:not(:last-child) {
            border-right: 1px solid #b3b3b3;
        }
    }
}
</style>
