<template>
    <div :class="$themeStyle($store.state.theme.theme)" class="tabs-unit-page un-shadow flex align-center fs20 fwb">
        <div v-for="(tab,tabIndex) in tabsData" :key="tabIndex" class="tabs-box cu-point flex-c flex-j-a" :class="tabsActive === tab.id ? 'active':''" @click="changeTab(tab.id)">
            <div class="tab">
                {{tab.title || tab.name}}
            </div>
            <el-image :class="tabsActive === tab.id ? 'show':'hide'" style="width: 24px; height: 6px" class="m-t-4" :src="require('../../assets/components/tabsActive.svg')" />
            <div :class="tabsActive !== tab.id ? 'show':'hide'" style="width: 24px; height: 6px" class="m-t-4" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        tabsData: {
            type: Array,
            default: () => [],
        },
        tabsActive: {
            type: [String, Number],
            default: null,
        },
    },
    data() {
        return {};
    },
    methods: {
        changeTab(id) {
            this.$emit("changeTab", id);
        },
    },
};
</script>

<style lang="scss" scoped>
.tabs-unit-page {
    overflow: auto;
    .tabs-box {
        margin-right: 30px;
        text-wrap: nowrap;
    }
    .active {
        .tab {
            transition: 0.5s;
            color: $themeColor;
        }
    }
    .hide {
        opacity: 0;
        transition: 0.5s;
    }
    .show {
        opacity: 1;
        transition: 0.5s;
    }
}
.light {
    .tab {
        transition: 0.5s;
        color: $darkSubFontColor;
    }
}

::-webkit-scrollbar {
    height: 4px; /* 对于垂直滚动条的宽度 */
    background-color: transparent; /* 滚动条的背景色 */
}

/* 定义滚动条轨道的样式 */
::-webkit-scrollbar-track {
    background: transparent; /* 轨道的背景色 */
}

/* 定义滚动条滑块的样式 */
::-webkit-scrollbar-thumb {
    background: transparent; /* 滑块的背景色 */
    border-radius: 4px; /* 滑块的圆角 */
}


::-webkit-scrollbar-track:hover {
    background: #e1e1e1; /* 轨道的背景色 */
}
::-webkit-scrollbar:hover {
    height: 4px; /* 对于垂直滚动条的宽度 */
    background-color: #f9f9f9; /* 滚动条的背景色 */
}
/* 定义当滑块悬停或活动时的样式 */
::-webkit-scrollbar-thumb:hover {
    background: #a1a1a1; /* 悬停状态下的背景色 */
}
</style>