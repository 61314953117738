import { render, staticRenderFns } from "./VueTabs.vue?vue&type=template&id=d5b7b88c&scoped=true"
import script from "./VueTabs.vue?vue&type=script&lang=js"
export * from "./VueTabs.vue?vue&type=script&lang=js"
import style0 from "./VueTabs.vue?vue&type=style&index=0&id=d5b7b88c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d5b7b88c",
  null
  
)

export default component.exports