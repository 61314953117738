//封装axios
import axios from "axios"
import {
    aesdecrypt,
    aesencrypt,
    getKeyAES
} from "@/utils/aes.js"
import {
    rsaencrypt
} from "@/utils/rsa.js"
import store from '@/store'
import {
    Message
} from 'element-ui'
import {
    getToken
} from '@/utils/auth'
// const url = 'http://192.168.110.25:8200' //黄金涛
// const url = 'http://192.168.110.29:8200' //余俊成
// const url = 'http://192.168.110.28:8200' //李佳
// const url = 'http://192.168.101.2:8301/api'

const url = process.env.VUE_APP_API
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
const service = axios.create({
    withCredentials: false, // send cookies when cross-domain requests
    timeout: 0, // 10 * 1000 // request timeout 10s
})

service.interceptors.request.use(
    async config => {
            if (config.type == 'file') {
                config.url = process.env.VUE_APP__URL + config.url
            } else {
                config.url = url + config.url
            }
            // if (config.data.isLogin) {
            //     getToken()
            // }
            const token = getToken()
            if (token) {
                config.headers['Authorization'] = token
            }
            //给PC端请求加头，方便后面移动端开发
            config.headers['X-Custom-Application-Category'] = 'pc'
            if (!config.data) config.data = {}
            //判断数据是否加密
            if (config?.data["isEncrypt"] && process.env.VUE_APP_OPENENCRYPT == '1') {
                //用aes对数据进行加密
                let i = getKeyAES()
                let k = getKeyAES()
                let s = aesencrypt(config.data, k, i)
                config.data = {
                    secretStr: s + rsaencrypt(k) + rsaencrypt(i)
                }
                return config
            }
            return config
        },
        error => {
            // do something with request error
            console.log(error) // for debug
            return Promise.reject(error)
        }
)

service.interceptors.response.use((response) => {
        // 2xx 范围内的状态码都会触发该函数。
        // 对响应数据做点什么
        const {
            data
        } = response
        //请求报错
        if (data.code != 200 || data.code == 1000) {
            if (data.code === 401) {
                store.dispatch('user/removeToken')
            }
            Message.error(data.message)
            return Promise.reject(new Error(data.message || 'Error'))
        } else {
            return data
        }
    },

    error => {
        const response = error.response
        if (response) {
            if (response.status === 401) {
                Message.error('缺少登录信息，需要登录过后，才能查看！')
                store.dispatch('user/removeToken')
                return
            }
            return Promise.reject(error)
        }
        Message.error(error.message)
        return Promise.reject(error)
    }
)
export default service