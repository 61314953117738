<template>
    <div :style="styleObject">
        <el-image class="model-icon sub-box text-center" :src="getResouceCover(url)" fit="cover" v-if="url"></el-image>
        <div v-else class="model-icon sub-box flex-j-c flex-a-c">
            <i class="el-icon-user"></i>
        </div>
    </div>
</template>

<script>
import { resouceCover } from "@/api/user";
export default {
    name: "VueAvatar",
    props: {
        url: {
            type: String,
            default: "",
        },
        width: {
            type: Number,
            default: 30,
        },
    },
    computed: {
        styleObject() {
            return {
                "--width": this.width + "px",
                "--size": this.width / 2 + "px",
            };
        },
    },
    methods: {
        getResouceCover(key) {
            return resouceCover(key);
        },
    },
};
</script>

<style lang="scss" scoped>
.model-icon {
    width: var(--width);
    height: var(--width);
    border-radius: var(--width);
    .el-icon-user {
        font-size: var(--size);
    }
}
.sub-box {
    background-color: #f6f6f6;
}
</style>