<template>
    <div class="item-title flex" :style="titleStyle">
        <span class="flex1">
            <b class="title" :class="fontColor?'theme-font fwb':''" :style="fontStyle">{{ title }}</b>
            <slot name="desc">
                <span v-if="desc" class="desc">{{ desc }}</span>
            </slot>
        </span>
        <slot name="action" />
    </div>
</template>

<script>
export default {
    name: "ItemTitle",
    props: {
        title: {
            type: String,
            default: "title",
            required: true,
        },
        desc: {
            type: String,
        },
        marginTop: {
            type: Number,
            default: 0,
        },
        marginBottom: {
            type: Number,
            default: 16,
        },
        fontColor: {
            type: Boolean,
            default: false,
        },
        fontSize: {
            type: Number,
            default: 16,
        },
    },
    computed: {
        titleStyle() {
            return {
                "margin-top": (this.marginTop ?? "0") + "px",
                "margin-bottom": this.marginBottom + "px",
            };
        },
        fontStyle() {
            return {
                "font-size": (this.fontSize ?? "16") + "px",
            };
        },
    },
};
</script>

<style scoped lang="scss">
.item-title {
    .title {
        position: relative;
        padding-left: 6px;
        font-size: 16px;
        //font-weight: bold;

        &::before {
            border-radius: 4px;
            content: "";
            width: 3px;
            height: 100%;
            background-color: #0077fe;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    .desc {
        padding-left: 16px;
        font-size: 14px;
        color: #8c8c8c;
    }
}
</style>
