<template>
    <div class="flex align-center search" :style="{width:width+'px'}">
        <el-input v-model="value" placeholder="请输入内容" :maxlength="50" class="input" />
        <div class="search-button cu-point flex flex-c flex-j-a" @click="doSearch">
            <i class="el-icon-search fs28"></i>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        width: {
            type: Number,
            default: 300,
        },
    },
    data() {
        return {
            value: "",
        };
    },
    methods: {
        doSearch() {
            this.$emit("search", this.value);
        },
    },
};
</script>

<style lang="scss" scoped>
.search {
    ::v-deep .el-input__inner {
        padding-left: 26px;
        height: 40px;
        font-size: 16px;
        border-top-left-radius: 32px;
        border-bottom-left-radius: 32px;
        border: none;
        box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.16);
    }
    .search-button {
        z-index: 1;
        margin-left: -24px;
        width: 96px;
        height: 40px;
        color: $fontColorDark;
        background-color: $themeColor;
        border-radius: 32px;
        box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.16);
    }
}
</style>