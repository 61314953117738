<template>
    <div :class="$themeStyle($store.state.theme.theme)" class="un-shadow flex flex-end">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :hide-on-single-page="total < pageSize" :pageSizes="pageSizes" :pageSize="pageSize" :layout="layout" :total="total">
        </el-pagination>
    </div>
</template>

<script>
export default {
    props: {
        layout: {
            type: String,
            default: "total, sizes, prev, pager, next, jumper",
        },
        pageSize: {
            type: Number,
            default: 10,
        },
        pageSizes: {
            type: Array,
            default: () => [10, 20, 50, 100],
        },
        total: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {};
    },
    methods: {
        /* 翻页 */
        handleCurrentChange(val) {
            this.$emit("pageChange", val);
        },
        /* 每页 val 条 */
        handleSizeChange(val) {
            this.$emit("sizeChange", val);
        },
    },
};
</script>

<style lang="scss" scoped>
.dark {
    ::v-deep .el-input__inner {
        background-color: transparent;
        color: $fontColorDark;
        border-color: $greyFontColor;
    }
    ::v-deep .el-pagination__total{
        color: $fontColorDark;
    }
    ::v-deep .el-pagination__jump{
        color: $fontColorDark;
    }
    ::v-deep .el-pagination button {
        background-color: transparent;
    }
    ::v-deep .number{
        background-color: transparent;
        color: $fontColorDark;
    }
    ::v-deep .btn-next{
        color: $fontColorDark;
    }
    ::v-deep .number:hover{
        color: $themeColor;
    }
    ::v-deep .btn-next:hover{
        color: $themeColor;
    }
    ::v-deep .active{
        color: $themeColor;
    }
}
.light{
    background-color: transparent !important;
}
</style>