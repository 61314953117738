import request from "@/utils/request";

export function resouceCover(key) {
  return `http://media.qilongkj.com/${key}`;
}

//data.isEncrypt = 1 数据是否加密，加密需要再data里面加上这个参数，不加密不需要做任何操作
//账号登录
export function login(data) {
  data.isEncrypt = 1;
  return request({
    url: "/user/login",
    method: "post",
    data,
  });
}
//获取验证码
export function getCode(data) {
  return request({
    url: "/user/sms/verifyCode",
    method: "post",
    data,
  });
}
//注册账号
export function registration(data) {
  return request({
    url: "/user/registration",
    method: "post",
    data,
  });
}
//验证码登录
export function codeLogin(data) {
  data.isEncrypt = 1;
  return request({
    url: "/user/verifyCodeLogin",
    method: "post",
    data,
  });
}

export function currentUser() {
  return request({
    url: "/user/loginInfo",
    method: "get",
  });
}

export function logout() {
  return request({
    url: "/auth/user/logout/token",
    method: "post",
  });
}

export function getTeacherDepartment() {
  return request({
    url: "/base/department/teacherDepartment",
    method: "post",
  });
}

export function getSchoolDetail() {
  return request({
    url: "/base/school/detail",
    method: "get",
  });
}

export function getAuthBtn(data) {
  return request({
    url: "/base/menu/findMenuButton",
    method: "post",
    data,
  });
}

export function getMenus(params) {
  return request({
    url: "/base/menu/findUserMenuTree",
    method: "get",
    params,
  });
}

export function getAccountTypes() {
  return request({
    url: "/base/user/findUserTypeList",
    method: "get",
  });
}

export function getSchool() {
  return request({
    url: "/user/school/selection",
    method: "get",
  });
}

export function getCampus(params) {
  return request({
    url: "/user/campus/selection",
    method: "get",
    params,
  });
}

export function perfectEnterpriseInfo(data) {
  return request({
    url: "/user/perfectEnterpriseInfo",
    method: "post",
    data,
  });
}

export function enterpriseType() {
  return request({
    url: "/user/dataDictionary/enterpriseType",
    method: "get",
  });
}

export function enterpriseScale() {
  return request({
    url: "/user/dataDictionary/enterpriseScale",
    method: "get",
  });
}

export function enterpriseIndustry() {
  return request({
    url: "/user/dataDictionary/enterpriseIndustry",
    method: "get",
  });
}

export function businessStatus() {
  return request({
    url: "/user/dataDictionary/businessStatus",
    method: "get",
  });
}

/* 获取个人信息 */
export function userInfo(data) {
  return request({
    url: "/user/userInfo",
    method: "post",
    data,
  });
}

//获取upload token
export function getOssToken() {
  return request({
    url: "/user/ossToken",
    method: "post",
  });
}

/* 修改头像 */
export function userAvatar(data) {
  return request({
    url: "/user/avatar",
    method: "post",
    data,
  });
}

/* 修改真实姓名 */
export function userRealName(data) {
  return request({
    url: "/user/realName",
    method: "post",
    data,
  });
}

/* 修改用户名 */
export function userUserName(data) {
  return request({
    url: "/user/uname",
    method: "post",
    data,
  });
}

/* 修改性别 */
export function userSex(data) {
  return request({
    url: "/user/sex",
    method: "post",
    data,
  });
}

/* 修改紧急联系人 */
export function exigencyContactInfo(data) {
  return request({
    url: "/user/exigencyContactInfo",
    method: "post",
    data,
  });
}

/* 修改紧急联系人手机号 */
export function exigencyContactInfoPhone(data) {
  return request({
    url: "/user/exigencyContactInfoPhone",
    method: "post",
    data,
  });
}

/* 修改邮箱 */
export function userEmail(data) {
  return request({
    url: "/user/email",
    method: "post",
    data,
  });
}

/* 查询个人简历详情 */
export function myCenterResumeDetails() {
  return request({
    url: "/employment/resume/detail",
    method: "get",
  });
}

/* 保存基础信息 */
export function saveBaseInfo(data) {
  return request({
    url: "/employment/resume/save/baseInfo",
    method: "post",
    data,
  });
}

/* 保存求职意向 */
export function saveJobIntention(data) {
  return request({
    url: "/employment/resume/save/jobIntentionSave",
    method: "post",
    data,
  });
}

/* 保存工作经历 */
export function saveJobExperience(data) {
  return request({
    url: "/employment/resume/save/jobExperience",
    method: "post",
    data,
  });
}

/* 删除工作经历 */
export function removeJobExperience(data) {
  return request({
    url: "/employment/resume/removeJobExperience",
    method: "post",
    data,
  });
}

/* 保存项目经验 */
export function saveProjectExperiencee(data) {
  return request({
    url: "/employment/resume/save/projectExperience",
    method: "post",
    data,
  });
}

/* 删除项目经验 */
export function removeProjectExperience(data) {
  return request({
    url: "/employment/resume/removeProjectExperience",
    method: "post",
    data,
  });
}

/* 保存教学经历 */
export function saveEduExperience(data) {
  return request({
    url: "/employment/resume/save/eduExperience",
    method: "post",
    data,
  });
}

/* 删除教育经历 */
export function removeEduExperience(data) {
  return request({
    url: "/employment/resume/removeEduExperience",
    method: "post",
    data,
  });
}

/* 保存资格证书 */
export function saveCredential(data) {
  return request({
    url: "/employment/resume/save/credential",
    method: "post",
    data,
  });
}

/* 删除资格证书 */
export function removeCredential(data) {
  return request({
    url: "/employment/resume/remove/credential",
    method: "post",
    data,
  });
}

/* 保存荣誉奖项 */
export function saveHonor(data) {
  return request({
    url: "/employment/resume/save/honor",
    method: "post",
    data,
  });
}

/* 删除荣誉奖项 */
export function removeHonor(data) {
  return request({
    url: "/employment/resume/remove/honor",
    method: "post",
    data,
  });
}

/* 个人证书 */
export function userCredentialPage(data) {
  return request({
    url: "/employment/userCredential/manage/page",
    method: "post",
    data,
  });
}

/* 添加个人证书 */
export function userCredentialAdd(data) {
  return request({
    url: "/employment/userCredential/add",
    method: "post",
    data,
  });
}

/* 提交证书审核 */
export function userCredentialSubmit(data) {
  return request({
    url: "/employment/userCredential/submit",
    method: "post",
    data,
  });
}

/* 编辑证书 */
export function userCredentialModify(data) {
  return request({
    url: "/employment/userCredential/modify",
    method: "post",
    data,
  });
}


/* 删除证书 */
export function userCredentialRemove(data) {
  return request({
    url: "/employment/userCredential/remove",
    method: "post",
    data,
  });
}

/* 附件简历列表 */
export function filleResumePage() {
  return request({
    url: "/employment/resume/annex/list",
    method: "get",
  });
}

/* 新增附件简历 */
export function filleResumeAdd(data) {
  return request({
    url: "/employment/resume/annex/add",
    method: "post",
    data,
  });
}

/* 删除附件简历 */

export function filleResumeRemove(data) {
  return request({
    url: "/employment/resume/annex/remove",
    method: "post",
    data,
  });
}

/* 课程证书 */
export function myCertificate(data) {
  return request({
    url: "/teaching/courseLearnCertificate/myCertificate",
    method: "post",
    data,
  });
}

/* 沟通过 */
export function communicated(data) {
  return request({
    url: "/employment/position/communicated",
    method: "post",
    data,
  });
}

/* 已投递 */
export function delivered(data) {
  return request({
    url: "/employment/position/delivered",
    method: "post",
    data,
  });
}

/* 邀请面试 */
export function interviewPage(data) {
  return request({
    url: "/employment/interview/page",
    method: "post",
    data,
  });
}

/* offer */
export function offerPage(data) {
  return request({
    url: "/employment/offer/page",
    method: "post",
    data,
  });
}

/* 入职记录 */
export function uelPage(data) {
  return request({
    url: "/employment/uel/page",
    method: "post",
    data,
  });
}

/* 不合适 */
export function unsuited(data) {
  return request({
    url: "/employment/position/unsuited",
    method: "post",
    data,
  });
}


/* 忘记密码 */
export function fpd(data) {
  return request({
    url: "/user/fpd",
    method: "post",
    data,
  });
}
