<template>
    <div class="fiexd-menu-page flex-c border-24" :class="$themeStyle($store.state.theme.theme)">
        <!-- <div class="button-box p-8 cu-point flex-j-a fs12" @click="clickMenu(1)">
            <i class="el-icon-user icon"></i>
            <el-popover placement="right" width="100" trigger="click">
                <div>客服</div>
                <span slot="reference">客服</span>
            </el-popover>
        </div>
        <div class="button-box p-8 cu-point flex-j-a fs12" @click="clickMenu(2)">
            <i class="el-icon-warning-outline icon"></i>
            <span>帮助中心</span>
        </div>
        <div class="button-box p-8 cu-point flex-j-a fs12" @click="clickMenu(3)">
            <i class="el-icon-mobile-phone icon"></i>
            <el-popover placement="right" width="100" trigger="click">
                <div>下载图片</div>
                <span slot="reference">APP下载</span>
            </el-popover>
        </div> -->
        <div class="button-box p-8 cu-point flex-j-a fs12" :class="scrollTop > 700 ? 'show-menu' : 'hide-menu'" @click="clickMenu(4)">
            <i class="el-icon-arrow-up icon"></i>
            <span>回到顶部</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        scrollTop: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {};
    },
    methods: {
        clickMenu(status) {
            if (status === 4) {
                this.$emit("backTop");
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.fiexd-menu-page {
    position: fixed;
    bottom: 50px;
    right: 10px;
    z-index: 999;
    width: 40px;
    .button-box {
        height: 30px;
        text-align: center;
        span {
            display: none;
            color: $themeColor;
            cursor: pointer;
        }
    }
    .button-box:hover {
        .icon {
            display: none;
        }
        span {
            display: inline-block;
        }
    }
}

.show-menu {
    display: inline-block;
    /* opacity: 1;
    transition: 0.8s; */
}
.hide-menu {
    display: none;
    /* opacity: 0;
    transition: 0.8s; */
}
</style>
