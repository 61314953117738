<template>
    <div :class="scrollTop > 60 ? 'fiexd' : isTransparent ? 'home-page' : 'normal'">
        <div class="flex flex-j-sb align-center hader-view" :class="$themeStyle($store.state.theme.theme)">
            <div class="page-title cu-point" @click="backHome">
                <div>
                    <el-image :src="logo" alt="" class="img-size" />
                </div>
                <el-select v-model="activeSystem" placeholder="请选择" class="select-change" @change="changeSystem">
                    <el-option v-for="item in optionSystem" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
                <div class="model-box cu-point flex align-center m-l-20 fs14" @click.stop="myCenter" v-if="user && studentIs">
                    <div class="model-icon m-r-8" :class="$themeStyle($store.state.theme.theme)">
                        <i class="el-icon-user"></i>
                    </div>
                    <span>个人主页</span>
                </div>
            </div>
            <!-- <div class="page-title cu-point" @click="backHome">青云平步</div> -->
            <!-- <div v-show="scrollTop > 600 || !isTransparent">
                <HomePageSearch :isNomarl="false" />
            </div> -->
            <div class="header-right flex align-center">
                <!-- <div class="model-box cu-point flex align-center m-r-20 fs14" @click="changeThemColor(isDark ? '0' : '1')">
                    <div v-show="!isDark" class="model-icon m-r-8" :class="$themeStyle($store.state.theme.theme)">
                        <i class="el-icon-sunrise"></i>
                    </div>
                    <div v-show="isDark" class="model-icon m-r-8" :class="$themeStyle($store.state.theme.theme)">
                        <i class="el-icon-sunset"></i>
                    </div>
                    {{ isDark ? "白天模式" : "黑夜模式" }}
                </div> -->
                <!-- <div class="model-box cu-point flex align-center m-r-20 fs14" @click="jumpTo">
                    <div class="model-icon m-r-8" :class="$themeStyle($store.state.theme.theme)">
                        <i class="el-icon-mobile-phone"></i>
                    </div>
                    APP下载
                </div> -->
                <div class="model-box cu-point flex align-center fs14" @click="login" v-if="!user">
                    <div class="model-icon m-r-8" :class="$themeStyle($store.state.theme.theme)">
                        <i class="el-icon-user"></i>
                    </div>
                    <span>登录丨注册</span>
                </div>
                <div class="model-box cu-point flex align-center m-r-20 fs14" @click="historyGo" v-if="user && studentIs">
                    <span>返回上一页</span>
                </div>
                <!-- <div class="model-box cu-point flex align-center m-r-20 fs14" @click="myCenter" v-if="user && studentIs">
                    <div class="model-icon m-r-8" :class="$themeStyle($store.state.theme.theme)">
                        <i class="el-icon-user"></i>
                    </div>
                    <span>个人主页</span>
                </div> -->
                <div class="model-box cu-point flex align-center fs14" v-if="token">
                    <el-dropdown trigger="click" @command="handleCommand">
                        <div class="flex align-center">
                            <el-avatar class="m-r-8" size="medium" :src="getResouceCover(user.avatar)">用户</el-avatar>
                            <span>{{ user.realName }}</span>
                        </div>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="personal">个人资料</el-dropdown-item>
                            <el-dropdown-item command="ecurity">安全设置</el-dropdown-item>
                            <el-dropdown-item command="gotoAdmin" v-if="!user.student">管理系统</el-dropdown-item>
                            <el-dropdown-item command="loginOut" divided>退出登录</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <div class="model-box cu-point flex align-center fs14" v-if="!token">
                    <div class="flex align-center" @click="handleCommand('gotoLogin')">
                        <el-avatar class="m-r-8" size="medium">登录</el-avatar>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HomePageSearch from "../views/home/components/HomePageSearch.vue";
import { resouceCover } from "@/api/user";
import { getToken } from "@/utils/auth";
export default {
    components: {
        HomePageSearch,
    },
    props: {
        scrollTop: {
            type: Number,
            default: 0,
        },
        isTransparent: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isDark: 0,
            activeSystem: 1,
        };
    },
    computed: {
        optionSystem() {
            return this.$store.state.user.user.student
                ? [
                      { name: "在线课程", id: 1 },
                      { name: "求职招聘", id: 2 },
                      { name: "实习实训", id: 3 },
                  ]
                : [
                      { name: "在线课程", id: 1 },
                      { name: "求职招聘", id: 2 },
                  ];
        },
        user() {
            return JSON.parse(JSON.stringify(this.$store.state.user.user));
        },
        logo() {
            switch (this.$store.state.user.logo) {
                case 1:
                    return require("@/assets/logo1.png");
                case 2:
                    return require("@/assets/logo1.png");
                case 3:
                    return require("@/assets/logo1.png");
            }
        },
        token() {
            return this.$store.state.user.token;
        },
        studentIs() {
            return this.$store.state.user.user.student;
        },
    },
    created() {},
    mounted() {
        if (sessionStorage.getItem("activeSystem")) {
            this.activeSystem = parseInt(
                JSON.parse(
                    JSON.stringify(sessionStorage.getItem("activeSystem"))
                )
            );
            this.$store.commit("user/SET_LOGO", this.activeSystem);
        } else {
            if (window.location.hash == '#/recruitmentPositions') {
                sessionStorage.setItem("activeSystem", 2);
                this.activeSystem = 2
            } else if (window.location.hash == '#/home') {
                sessionStorage.setItem("activeSystem", 1);
                this.activeSystem = 1
            } else {
                sessionStorage.setItem("activeSystem", 3);
                this.activeSystem = 3
            }
        }
    },
    methods: {
        historyGo() {
            history.back()
        },
        changeSystem(item) {
            this.$store.commit("user/SET_LOGO", item);
            sessionStorage.setItem("activeSystem", item);
            if (item == 1) {
                this.$router.push("/home");
            } else if (item == 2) {
                this.$router.push("/recruitmentPositions");
            } else if (item == 3) {
                this.$router.push("/internshipTraining");
            }
        },
        getResouceCover(key) {
            return resouceCover(key);
        },
        handleCommand(command) {
            if (command === "personal") {
                this.$router.push("/personalCenter/personalData");
            } else if (command === "ecurity") {
                this.$router.push("/personalCenter/accountSecurity");
            } else if (command === "loginOut") {
                this.$message.success("退出成功");
                this.$store.dispatch("user/removeToken").then(() => {
                    this.$router.push("/login");
                });
            } else if (command === "gotoAdmin") {
                window.location.href = process.env.VUE_APP_PATH_URL;
            } else {
                sessionStorage.removeItem("activeSystem");
                this.$router.push("/login");
            }
        },
        changeThemColor(e) {
            this.isDark = parseInt(e);
            this.$store.commit("theme/setTheme", e);
        },
        jumpTo() {
            this.$router.push("/message");
        },
        login() {
            sessionStorage.removeItem("activeSystem");
            this.$router.push("/login");
        },
        myCenter() {
            if (this.$store.state.user.user.id) {
                this.$router.push("/myCenter");
            } else {
                this.$message.warning("请先登录");
            }
        },
        backHome() {
            if (this.activeSystem == 1) {
                this.$router.push("/home");
            } else if (this.activeSystem == 2) {
                this.$router.push("/recruitmentPositions");
            } else {
                this.$router.push("/internshipTraining");
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.system-box {
    // width: 130px;
    padding: 0 16px;
    height: 40px;
    line-height: 40px;
    border-radius: 40px;
    text-align: center;
    background-color: #dcf1ff;
    color: #0077fe;
}

.post {
    background-color: #eafcfb;
    color: #00ca97;
}

.normal {
    .hader-view {
        padding: 12px;
        position: relative;
        top: 0;
        left: 0;
        z-index: 99;

        .header-right {
            .model-box {
                line-height: 30px;

                .model-icon {
                    margin-right: 8px;
                    text-align: center;
                    width: 30px;
                    height: 30px;
                    border-radius: 30px;
                }
            }
        }
    }

    .dark {
        background-color: $midBackGroundColor;
    }
}

.home-page {
    .hader-view {
        padding: 12px;
        box-shadow: none !important;
        position: relative;
        top: 0;
        left: 0;
        z-index: 99;
        background-color: rgba(
            $color: $boxBackGroundColor,
            $alpha: 0
        ) !important;
        border: none !important;
        color: $fontColorDark !important;

        .header-right {
            .model-box {
                line-height: 30px;

                .model-icon {
                    margin-right: 8px;
                    text-align: center;
                    width: 30px;
                    height: 30px;
                    border-radius: 30px;
                    background-color: $boxBackGroundColor;
                    color: $fontColor;
                }
            }
        }
    }
}

.fiexd {
    transition: 0.8s;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;

    .hader-view {
        padding: 20px;
        position: relative;
        top: 0;
        left: 0;
        z-index: 99;

        .header-right {
        }
    }

    .dark {
        background-color: $midBackGroundColor;
    }
}

.page-title {
    // width: 340px;
    display: flex;
}

::v-deep .select-change {
    margin-left: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .el-select {
        background-color: unset;
    }

    .el-input__inner {
        background-color: #dcf1ff;
        border: none;
        width: 100px;
        color: #0077fe;
        border-radius: 40px;
        height: 30px;
    }
}

.img-size {
    height: 40px;
    width: 120px;
}

::v-deep .el-dropdown {
    color: unset;
}

::v-deep .el-dropdown-menu__item .el-dropdown-menu__item {
    line-height: 26px;
}
.model-box {
    line-height: 30px;

    .model-icon {
        margin-right: 8px;
        text-align: center;
        width: 30px;
        height: 30px;
        border-radius: 30px;
    }
}
</style>
