import Vue from "vue";
import App from "./App.vue";
import ElementUI from "element-ui";
import router from "./router";
import store from "./store";
import Vuex from "vuex";
import "@/styles/styleSheets.scss";
import "element-ui/lib/theme-chalk/index.css";
import { themeStyle } from "@/utils/themeColorConfig";
import { filterList } from "@/utils/common";
import { resourceType } from "@/utils/resourceType";
import AmapVue from "@amap/amap-vue";
import "moment/locale/zh-cn";
import * as echarts from "echarts";
import Plugins from "@/plugins";
import VueTabs from "@/components/Tabs/VueTabs.vue";
import ShowMore from "./components/ShowMore.vue";
import VueCoreVideoPlayer from "vue-core-video-player";
import BreadCrumb from "./components/BreadCrumb/BreadCrumb.vue";
import Title from "./components/TitleView/TitleView.vue";
import Pagination from "./components/Pagination/Pagination.vue";
import PageSearch from "./components/PageSearch/PageSearch.vue";
import VueAvatar from "./components/VueAvatar/VueAvatar.vue";
import MyEmpty from "./components/MyEmpty/MyEmpty.vue";
import Badge from "@/components/Badge/Badge.vue";
import ItemTitle from "@/components/ItemTitle.vue";
import commonImg from '@/utils/commonUtil'

AmapVue.config.key = process.env.VUE_APP_MAP_KEY;
AmapVue.config.plugins = [
  "AMap.ToolBar",
  "AMap.MoveAnimation",
  "AMap.PlaceSearch",
  "AMap.AutoComplete",
  "AMap.Geocoder",
];
Vue.use(AmapVue);
Vue.component("Pagination", Pagination);
Vue.component("PageSearch", PageSearch);
Vue.component("Title", Title);
Vue.component("VueTabs", VueTabs);
Vue.component("ShowMore", ShowMore);
Vue.component("BreadCrumb", BreadCrumb);
Vue.component("MyEmpty", MyEmpty);
Vue.component("Badge", Badge);
Vue.component("VueAvatar", VueAvatar);
Vue.component("ItemTitle", ItemTitle);
/* 试题 */
Vue.prototype.$questionType = [
  {
    type: 1,
    name: "单选题",
  },
  {
    type: 2,
    name: "多选题",
  },
  {
    type: 3,
    name: "判断题",
  },
  {
    type: 4,
    name: "填空题",
  },
  {
    type: 5,
    name: "简答题",
  },
  {
    type: 6,
    name: "阅读题",
  },
];
Vue.prototype.$questionDifficulty = [
  {
    type: 1,
    name: "简单",
  },
  {
    type: 2,
    name: "中等",
  },
  {
    type: 3,
    name: "困难",
  },
];
Vue.use(ElementUI, {
  size: "small",
});

Vue.use(VueCoreVideoPlayer, {
  lang: "zh-CN",
});
Vue.use(ElementUI);
Vue.use(Plugins);
Vue.use(Vuex);
Vue.prototype.$encrypt = function (password) {
  const encryptor = new JSEncrypt({
    default_key_size: 2048,
  });
  encryptor.setPublicKey(process.env.VUE_APP_PUBLIC_KEY); // 设置公钥
  return encryptor.encrypt(password);
};

Vue.config.productionTip = false;
Vue.prototype.$themeStyle = themeStyle;
Vue.prototype.$resourceType = resourceType;
Vue.prototype.$echarts = echarts;
Vue.prototype.$filterList = filterList;
Vue.prototype.$commonImg = commonImg

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
