import { fileTypes } from '@/utils/common'

const CommonPlugin = {
  install(Vue) {
    Vue.mixin({
      data() {
        return {
          fileTypes: fileTypes
        }
      }
    })
  }
}

export default CommonPlugin
