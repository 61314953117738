import Cookies from 'js-cookie'
const tokenKey = 'qypb-token'
export function getToken() {
  return Cookies.get(tokenKey)
}

// export function setToken(token) {
//   return Cookies.set(tokenKey, `Bearer ${token.tokenValue}`)
// }

// export function removeToken(token) {
//   return Cookies.remove(tokenKey)
// }

export function setToken(token) {
  return Cookies.set(tokenKey, `Bearer ${token.tokenValue}`, { expires: token.expire, domain: process.env.VUE_APP_SHARE_DOMAIN_NAME  }) 
}

export function removeToken() {
  return   Cookies.remove(tokenKey, { domain: process.env.VUE_APP_SHARE_DOMAIN_NAME })
}