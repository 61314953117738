<template>
  <div class="empty-container">
    <img class="empty-img" src="@/assets/empty.png" alt="" />
    <div class="empty-desc">{{ desc }}</div>
  </div>
</template>

<script>
export default {
  name: 'MyEmpty',
  props: {
    desc: {
      type: String,
      default: '暂无数据'
    }
  }
}
</script>

<style scoped lang="scss">
.empty-container {
  width: 100%;
  text-align: center;
  padding: 24px;
  user-select: none;

  .empty-img {
    width: 100px;
    margin-bottom: 12px;
    margin-left: 15px;
  }

  .empty-desc {
    color: #909399;
    font-size: 13px;
  }
}
</style>
