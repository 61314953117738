export function getType(text) {
  for (let i = 0; i < imgFile.length; i++) {
    if (imgFile[i].type === text) {
      return imgFile[i].url
    }
  }
}
 const imgType = [
  'bmp',
  'jpg',
  'png',
  'tif',
  'gif',
  'pcx',
  'tga',
  'exif',
  'fpx',
  'svg',
  'psd',
  'cdr',
  'pcd',
  'dxf',
  'ufo',
  'eps',
  'ai',
  'raw',
  'WMF',
  'webp',
  'avif',
  'apng'
]
 const imgFile = [{
      type: 'ppt',
      url: require('@/assets/Internship/powerpoint.png')
    },
    {
      type: 'pptx',
      url: require('@/assets/Internship/powerpoint.png')
    },
    {
      type: 'pdf',
      url: require('@/assets/Internship/pdf.png')
    },
    {
      type: 'doc',
      url: require('@/assets/Internship/word.png')
    },
    {
      type: 'docx',
      url: require('@/assets/Internship/word.png')
    },
    {
      type: 'word',
      url: require('@/assets/Internship/word.png')
    },
    {
      type: 'xls',
      url: require('@/assets/Internship/excel.png')
    },
    {
      type: 'xlsx',
      url: require('@/assets/Internship/excel.png')
    },
    {
      type: 'all',
      url: require('@/assets/Internship/text.png')
    }
  ]

export default { imgType, imgFile, getType }
