//删选人员
export function filterList(data) {
  data.forEach((item) => {
    if (item.children !== null && item.children.length === 0) {
      item.children = null
    }
    if (item.children && item.children.length > 0) {
      filterList(item.children)
    }
    return data
  })
}
export function expectSalary(minSalary, maxSalary) {
  if (!minSalary || minSalary === '0') {
    return '面议'
  }
  if (!maxSalary) {
    return `${minSalary}以上`
  }
  return `${minSalary}-${maxSalary}K`
}

export function changeType(num) {
  switch (num) {
    case 1:
      return '老师';
    case 2:
      return '学生';
  }
}


// 上传类型
export const fileTypes = {
  img: '.jpg, .jpeg, .png, .bmp, .tif, .gif',
  file: '.doc,.docx,.pdf',
  fileimg: '.doc,.docx,.pdf,.jpg, .jpeg, .png, .bmp, .tif, .gif,.ppt,.xlsx,.pptx,.xlx,.xls',
  upFile: '.doc,.docx,.pdf,.jpg, .jpeg, .png, .bmp, .tif, .gif,.ppt,.xlsx,.pptx,.xlx,.xls,.mp4,.m3u8,.mp3'
}