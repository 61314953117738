<template>
    <div class="box-title fwb fs16 flex align-center">
        <span class="m-r-8"></span>
        {{title || 'title'}}
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: "title",
        },
    },
};
</script>

<style lang="scss" scoped>
.box-title {
    > span {
        display: inline-block;
        height: 16px;
        width: 2px;
        background-color: $themeColor;
    }
}
.light {
    .box-title {
        color: $heavierFontColor;
    }
}
</style>