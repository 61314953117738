<template>
    <div class="flex1 flex-c">
        <HeaderView :scrollTop="scrollTop" v-if="currentPath != '/login' && currentPath != '/register' && currentPath != '/companyInfo'" :isTransparent="isTransparent" />
        <transition name="el-fade-in-linear">
            <router-view class="main-page-box" :style="{ width: boxWidth + 'px' }">
                <BreadCrumb />
            </router-view>
        </transition>
        <FooterView v-if="isBottom && currentPath != '/companyInfo'" />
        <FiexdMenu :scrollTop="scrollTop" @backTop="backTop" />
    </div>
</template>

<script>
import HeaderView from "@/components/HeaderView.vue";
import FooterView from "@/components/FooterView.vue";
import FiexdMenu from "./views/home/components/FiexdMenu";
export default {
    components: {
        HeaderView,
        FooterView,
        FiexdMenu,
    },
    name: "App",
    data() {
        return {
            scrollTop: 0,
            timer: null,
            isTransparent: false,
            isBottom: false,
            currentPath: "/login",
            boxWidth: 0,
        };
    },
    watch: {
        $route(to, from) {
            const path = to.path;
            this.currentPath = path;
            this.isTransparent =
                path === "/home" || path === "/recruitmentPositions";
            this.isBottom = to.meta?.needBottom ? false : true;
        },
    },
    mounted() {
        window.addEventListener("scroll", this.scrolling);
        this.setBoxWidth();
        window.addEventListener("resize", this.setBoxWidth);
    },
    destroyed() {
        window.removeEventListener("resize", this.setBoxWidth);
    },
    methods: {
        backTop() {
            this.timer = setInterval(function () {
                var left =
                    window.pageYOffset ||
                    document.documentElement.scrollTop ||
                    document.body.scrollTop;
                var step = Math.ceil((left - 0) / 10);
                window.scroll(0, left - step);
                if (left == 0) {
                    clearInterval(this.timer);
                }
            }, 20);
        },
        setBoxWidth() {
            if (window.innerWidth < 1920) {
                this.boxWidth = window.innerWidth;
            } else {
                this.boxWidth = 1920;
            }
            console.log(this.boxWidth);
        },
        scrolling() {
            let scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;
            let scrollStep = scrollTop - this.oldScrollTop;
            this.oldScrollTop = scrollTop;
            if (scrollStep > 0) {
                clearInterval(this.timer);
            }
            this.scrollTop = scrollTop;
        },
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.scrolling);
    },
};
</script>

<style>
@import "./styles/styleSheets.scss";
@import "./styles/common.scss";

html {
    width: 100%;
    height: 100%;
}

body {
    width: 100%;
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    font-family: PingFang SC;
}

::-webkit-scrollbar {
    width: 4px;
    height: 6px;
}

/* .el-scrollbar__bar.is-vertical {
    width: 4px;
} */

::-webkit-scrollbar-thumb:vertical {
    height: 5px;
    cursor: pointer;
    background-color: #dddee0;
    -webkit-border-radius: 6px;
}

.main-page-box {
    margin: 0 auto;
}
</style>
