<template>
    <div :class="$themeStyle($store.state.theme.theme)" class="un-shadow">
        <div class="flex align-center home-page-search" v-if="isNomarl">
            <el-input v-model="value" placeholder="请输入课程名称" :maxlength="50" class="input"></el-input>
            <div class="search-button cu-point flex flex-c flex-j-a" @click="doSearch">
                <i class="el-icon-search fs28"></i>
            </div>
        </div>
        <div class="flex align-center home-page-search-fiexd" v-else :style="{width:width+'px'}">
            <el-input v-model="value" placeholder="请输入内容" :maxlength="50" class="input"></el-input>
            <div class="search-button cu-point flex flex-c flex-j-a">
                <i class="el-icon-search fs28"></i>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isNomarl: {
            type: Boolean,
            default: true,
        },
        width: {
            type: Number,
            default: 500,
        },
    },
    data() {
        return {
            value: "",
        };
    },
    methods: {
        doSearch() {
            this.$router.push({
                path: "/student/courseList",
                query: {
                    keyword: this.value,
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.home-page-search,
.home-page-search-fiexd {
    box-shadow: none;
    // max-width: 1000px;
    // min-width: 400px;
    width: 1200px;
    .input {
        ::v-deep .el-input__inner {
            padding-left: 46px;
            height: 60px;
            font-size: 16px;
            border-top-left-radius: 32px;
            border-bottom-left-radius: 32px;
            border: none;
            box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.16);
        }
    }
    .search-button {
        z-index: 1;
        margin-left: -64px;
        width: 130px;
        height: 60px;
        color: $fontColorDark;
        background-color: $themeColor;
        border-radius: 32px;
        box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.16);
    }
}
.home-page-search-fiexd {
    width: 500px;
    .input {
        ::v-deep .el-input__inner {
            padding-left: 26px;
            height: 40px;
        }
    }
    .search-button {
        z-index: 1;
        margin-left: -24px;
        width: 96px;
        height: 40px;
        color: $fontColorDark;
        background-color: $themeColor;
        border-radius: 32px;
        box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.16);
    }
}
.dark {
    ::v-deep .el-input__inner {
        background-color: $borderColorDark !important;
    }
}
</style>